import { AppBar, Button, ThemeProvider, Toolbar, Typography } from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles';
import React from "react"
import Helmet from "react-helmet";

import './index.css';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#494949',
      },
      secondary: {
        main: '#0789B2'
      }
    },
  });

function getOrDefault(data: any, key: string, defaultValue: any = '') {
    if (!data) {
        return defaultValue;
    }

    if (!(key in data)) {
        return defaultValue;
    }

    return data[key];
}

function Header(props: any) {
    return (
        <div>
            <Helmet>
                {/* <head> */}
                    <meta name="title" property="og:title" content={getOrDefault(props.details, 'title')}/>
                    <meta property="og:type" content={getOrDefault(props.details, 'type', 'Article')}/>
                    <meta name="image" property="og:image" content={getOrDefault(props.details, 'img')}/>
                    <meta name="description" property="og:description" content={getOrDefault(props.details, 'description')}/>
                    <meta name="author" content="Spencer Seeger"/>
                    {/* <meta name="publish_date" property="og:publish_date" content="2019-10-21T00:00:00-0600"> */}
                {/* </head> */}
            </Helmet>
            <ThemeProvider theme={theme}>
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h5" className='full'>
                        <a href="/" className='main-icon'>
                            S
                        </a>
                    </Typography>
                    {/* <Button>
                        Test 1
                    </Button>
                    <Button>
                        Test 2
                    </Button> */}
                    <Typography variant="subtitle1">
                        <a href="/about" className='main-icon'>
                            About
                        </a>
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="body full">
                {props.children}
            </div>
            </ThemeProvider>
        </div>
    )
}

export default Header;